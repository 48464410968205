import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { getGameNameKeyByLotteryCode, TransactionAction } from '@app/util/utils';
import { BarcodeReaderService } from '@app/core/barcode/barcode-reader.service';
import { CancelableApiClient } from '@app/core/net/http/api/api-client';
import { WinPayPaperReq, WinPayReq } from '@app/core/net/http/api/models/win-pay';
import { ICancelableRequest } from '@app/core/net/http/api/types';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { Transaction } from '@app/core/services/transaction/transaction';
import { CancelFlag, ITransactionResponse, ITransactionTicket } from '@app/core/services/transaction/transaction-types';
import { BonusPayReq } from '@app/core/net/http/api/models/bonus-pay';
import { EsapParams } from '@app/core/configuration/esap';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { AppType } from '@app/core/services/store/settings';
import { LotteryGameCode } from '@app/core/configuration/lotteries';

/**
 * Модель одного элемента из списка операций.
 */
export interface ITotalCheckOperation {
	/**
	 * Дата и время операции.
	 */
	timestamp: number;

	/**
	 * Ключ локализации имени лотереи.
	 */
	gameName: string;

	/**
	 * Код игры.
	 */
	gameCode: number;

	/**
	 * Тип операции.
	 * Используется в качестве имени класса.
	 */
	operationType: TransactionAction;

	/**
	 * Ключ локализации типа операции.
	 */
	operationTypeLabel: string;

	/**
	 * Количество элементов в операции.
	 */
	count: number;

	/**
	 * Общее количество элементов в операции.
	 * Если задано, то будет отображена строка "{@link count} из {@link totalCount}".
	 */
	totalCount?: number;

	/**
	 * Сумма операции.
	 */
	summa: number;

	/**
	 * Ключ локализации результата операции.
	 */
	operationResult: string;

	/**
	 * Показывать ли иконку замка?
	 */
	showLocker?: number;

	/**
	 * Запрос, который был отправлен на сервер.
	 */
	request: ICancelableRequest | WinPayReq | WinPayPaperReq;

	/**
	 * Полученный ответ от сервера.
	 */
	response?: ITransactionResponse;
}

/**
 * Список состояний списка операций:
 * - {@link Collapsed} - список спрятан
 * - {@link Expanded} - список открыт
 */
export enum TotalCheckListState {
	Collapsed	= 'collapsed',
	Expanded	= 'expanded'
}

/**
 * Сервис для работы с функционалом подсчета общей суммы по чекам, отменам и выплатам.
 */
@Injectable({
	providedIn: 'root'
})
export class TotalCheckStorageService {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Текущая сумма чека.
	 */
	readonly totalCheckAmount$$ = new BehaviorSubject<number>(0);

	/**
	 * Содержит текущее состояние {@link TotalCheckListState} списка операций.
	 */
	readonly operationListState$$ = new BehaviorSubject<TotalCheckListState>(TotalCheckListState.Expanded);

	/**
	 * Содержит текущий список выполненных операций.
	 */
	readonly operationsList$$ = new BehaviorSubject<Array<ITotalCheckOperation>>([]);

	/**
	 * Содержит список всех выполненных операций.
	 */
	readonly operationsHistoryList$$ = new BehaviorSubject<Array<ITotalCheckOperation>>([]);

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {BarcodeReaderService} barcodeReaderService Сервис для работы со сканером штрих-кодов.
	 * @param appStoreService Сервис для работы с хранилищем приложения.
	 */
	constructor(
		private readonly barcodeReaderService: BarcodeReaderService,
		private readonly appStoreService: AppStoreService
	) {}

	/**
	 * Сбросить историю операций, итоговую сумму и оперативный список операций.
	 */
	clearHistory(): void {
		Logger.Log.i('TotalCheckStorageService', `clearHistory -> clear operation history`)
			.console();

		this.operationsHistoryList$$.next([]);
		this.totalCheckAmount$$.next(0);
		this.operationsList$$.next([]);
	}

	/**
	 * Сбросить итоговую сумму операций и оперативный список операций.
	 */
	clearAmount(): void {
		Logger.Log.i('TotalCheckStorageService', `clearAmount -> clear total check amount`)
			.console();

		// добавить все уникальные операции из оперативного списка в историю
		const arr = this.operationsHistoryList$$.value;
		this.operationsList$$.value.forEach(activeItem => {
			const itsNewItem = !arr.find(historyItem => historyItem.timestamp === activeItem.timestamp);
			if (itsNewItem) {
				arr.push(activeItem);
			}
		});
		this.operationsHistoryList$$.next(arr);

		this.totalCheckAmount$$.next(0);
		this.operationsList$$.next([]);
		this.operationListState$$.next(TotalCheckListState.Collapsed);
	}

	/**
	 * Зарегистрировать продажу лотереи.
	 * Если задан параметр transaction - это означает частичную продажи, когда не все билеты распечатаны.
	 *
	 * @param {ICancelableRequest} request Модель запроса.
	 * @param {ITransactionResponse} response Модель ответа.
	 * @param {Transaction} transaction Последняя транзакция. Если задано, то это означает частичную продажу.
	 */
	register(request: ICancelableRequest, response: ITransactionResponse, transaction?: Transaction): void {
		if (!Array.isArray(response.ticket)) {
			return;
		}

		// найти имя игры
		let gameName = '???';
		let gameCode = 0;
		if (request instanceof CancelableApiClient) {
			gameName = getGameNameKeyByLotteryCode(request, response);
			gameCode = request.gameCode;
		}

		// определить параметры для успешной и частично-успешной транзакции
		let operationType: TransactionAction;
		let operationTypeLabel: string;
		let operationResult: string;
		let count: number;
		let totalCount: number;
		let summa = this.calculateAllTicketSum(response);
		if (transaction) {
			operationTypeLabel = `action_log.oper_action_log_action_register`;
			count = transaction.getNumberOfPrinted();
			totalCount = response.ticket.length;
			summa = count * summa / response.ticket.length;
			operationResult = count > 0 ? `action_log.successful_partial` : `action_log.oper_action_log_result_cancel`;
			operationType = count > 0 ? TransactionAction.REGISTER_PARTIAL : TransactionAction.CANCEL_AUTO;
		} else {
			operationType = TransactionAction.REGISTER;
			operationTypeLabel = `action_log.oper_action_log_action_register`;
			operationResult = `action_log.successful`;
			count = response.ticket.length;
		}

		// пересчет количества билетов для стирачек (в них структура ответа немного другая)
		if (gameCode === LotteryGameCode.TML_BML) {
			count = 0;
			for (const ticket of response.ticket) {
				for (const serie of ticket.serie) {
					for (const range of serie.range) {
						count += range.count;
					}
				}
			}
		}

		Logger.Log.i('TotalCheckStorageService', `register -> detected (${operationType}) for game: (${gameName})`)
			.console();

		this.addOperation({
			timestamp: Date.now(),
			gameName,
			gameCode,
			operationType,
			operationTypeLabel,
			operationResult,
			count,
			totalCount,
			summa,
			request,
			response
		});

		this.operationListState$$.next(TotalCheckListState.Collapsed);
	}

	/**
	 * Отмена чека продавцом.
	 * При отмене чека рассчитать новую итоговую сумму.
	 *
	 * @param {Transaction} transaction Идентификатор транзакции, по которой была отмена.
	 */
	cancel(transaction: Transaction): void {
		const tid = transaction.store.params.trans_id;
		const makeNewOperation = (newOperation: ITotalCheckOperation): void => {
			newOperation.timestamp = Date.now();
			newOperation.summa = -newOperation.summa;
			newOperation.operationType = TransactionAction.CANCEL;
			newOperation.operationTypeLabel = `action_log.cancel_registration`;
			newOperation.operationResult = `action_log.successful`;
			newOperation.showLocker = 0;

			this.addOperation(newOperation);
		};

		// пропускаем только ручные отмены
		switch (transaction.cancelFlag) {
			case CancelFlag.Manual_BadTickets:
			case CancelFlag.Manual_NoMoney:
			case CancelFlag.Manual_PrintError:
				Logger.Log.i('TotalCheckStorageService', `detected CANCEL(${transaction.cancelFlag}) for transaction: ${tid}`)
					.console();
				break;
			default:
				Logger.Log.i('TotalCheckStorageService', `skipped CANCEL(${transaction.cancelFlag}) for transaction: ${tid}`)
					.console();

				return;
		}

		// сначала ищем запись в оперативном списке
		let operation = this.operationsList$$.value.find(p => p.request.trans_id === tid);
		if (operation) {
			// пометить признак "замок"
			operation.showLocker = 1;
			makeNewOperation({...operation});
		} else {
			operation = this.operationsHistoryList$$.value.find(p => p.request.trans_id === tid);
			if (operation) {
				makeNewOperation(operation);
				this.operationListState$$.next(TotalCheckListState.Expanded);
			}
		}
	}

	/**
	 * Выплата выигрыша по чеку.
	 *
	 * @param {WinPayReq | WinPayPaperReq} request Модель запроса на выплату.
	 * @param {string} barcode Штрих-код билета.
	 * @param {number} amount Сумма выигрыша в копейках.
	 */
	payment(request: WinPayReq | WinPayPaperReq, barcode: string, amount: number): void {
		Logger.Log.i('TotalCheckStorageService', `detected PAYMENT for BC: ${barcode}`)
			.console();
		const gameNameAndCode = this.detectGameNameAndCode(barcode);
		if (gameNameAndCode && gameNameAndCode.gameCode && gameNameAndCode.gameCode === 100) {
			gameNameAndCode.gameName = 'lottery.tmlbml.game_name';
		}
		this.addOperation({
			timestamp: Date.now(),
			...gameNameAndCode,
			operationType: TransactionAction.PAYMENT,
			operationTypeLabel: `action_log.oper_action_log_action_payment`,
			operationResult: `action_log.paid`,
			count: 1,
			summa: -amount * 0.01,
			request
		});
	}

	/**
	 * Операция выплаты бонуса по чеку и регистрации бонусных билетов.
	 * В списке операций будет отображаться 2 записи.
	 *
	 * @param {BonusPayReq} request Запроса на выплату.
	 * @param {ITransactionResponse} response Ответ на запрос продажи лотерейных билетов.
	 */
	paymentAndRegisterBonus(request: BonusPayReq, response: ITransactionResponse): void {
		Logger.Log.i('TotalCheckStorageService', `paymentAndRegisterBonus`)
			.console();

		const timestamp = Date.now();
		const arr: Array<ITotalCheckOperation> = [];
		const setOfGameCodes = new Set<number>();
		const allTickets = ('length' in response.ticket) ? response.ticket : [response.ticket];
		allTickets.forEach(ticket => {
			const {gameName, gameCode} = {...this.detectGameNameAndCode(ticket)};

			setOfGameCodes.add(gameCode);
			arr.push({
				timestamp,
				gameName,
				gameCode,
				operationType: TransactionAction.PRINT_BONUS,
				operationTypeLabel: `action_log.oper_action_log_action_register`,
				operationResult: `action_log.successful`,
				count: 1,
				summa: +ticket.bet_sum,
				request,
				response
			});
		});

		// запись о выплате
		this.addOperation({
			timestamp,
			...this.detectGameNameAndCode(request.params.get(EsapParams.BARCODE)),
			operationType: TransactionAction.PAYMENT,
			operationTypeLabel: `action_log.oper_action_log_action_payment`,
			operationResult: `action_log.paid`,
			count: 1,
			summa: -100,
			showLocker: setOfGameCodes.size,
			request
		});

		Array.from(setOfGameCodes.values())
			.map(gc => {
				const item = arr.filter(f => f.gameCode === gc);
				const count = item.length;
				const summa = item.reduce((p, c) => p + c.summa, 0);

				return {...item[0], count, summa};
			})
			.forEach(o => this.addOperation(o));
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Определить имя игры и код игры.
	 *
	 * @param {ITransactionTicket | string} value Штрихкод или модель билета.
	 * @returns {{gameName: string, gameCode: number}}
	 */
	private detectGameNameAndCode(value: ITransactionTicket | string): { gameName: string, gameCode: number } {
		const barcode = typeof value === 'string' ? value : value.mac_code;
		let gameName = typeof value !== 'string' ? value.game_name : undefined;
		let gameCode = 0;
		const analysis = this.barcodeReaderService.determineBarcodeType(barcode);
		if (analysis && analysis.detectedLotteryInfo && analysis.detectedLotteryInfo.lott_name) {
			gameName = analysis.detectedLotteryInfo.lott_name;
			gameCode = analysis.detectedGameCode;
		}

		return { gameName, gameCode };
	}

	/**
	 * Добавить операцию в массив.
	 *
	 * @param {ITotalCheckOperation} operation Операция.
	 */
	private addOperation(operation: ITotalCheckOperation): void {
		const arr = this.operationsList$$.value;

		if (this.appStoreService.Settings.appType === AppType.ALTTerminal) {
			arr.push(operation);
		} else {
			arr.unshift(operation);
		}

		this.operationsList$$.next(arr);

		this.totalCheckAmount$$.next(this.calculateAmount());
	}

	/**
	 * Рассчитать сумму по ставке, на основе ответа сервиса ЦС.
	 * @param {ITransactionResponse} response Ответ на запрос продажи лотерейных билетов.
	 */
	private calculateAllTicketSum(response: ITransactionResponse): number {
		const allTickets = ('length' in response.ticket) ? response.ticket : [response.ticket];
		return allTickets
			.map((m: ITransactionTicket) => {
				const betSum = parseFloat(m.bet_sum);

				return isNaN(betSum) ? 0 : betSum;
			})
			.reduce((p: number, c: number) => p + c, 0);
	}

	/**
	 * Рассчитать итоговую сумму.
	 */
	private calculateAmount(): number {
		return this.operationsList$$.value
			.map(m => m.summa)
			.reduce((p, c) => p + c, 0);
	}

}
