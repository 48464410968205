import {UpdateDrawInfoLottery} from "@app/core/net/http/api/models/update-draw-info";

/**
 * Mock-тиражи для игры "Спортпрогноз".
 */
export const DRAWS_FOR_GAME_SPORTPROGNOZ: UpdateDrawInfoLottery | any = {
	lottery: {
		'currency': '',
		'draws': [{
			'draw': {
				'bet_sum': '5.00',
				'code': '1525',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'

				},
				'dr_bdate': '2029-05-25 12:42:36',
				'dr_edate': '2029-05-25 12:42:37',
				'num': '388',
				'sale_bdate': '2029-04-11 12:42:35',
				'sale_edate': '2029-05-25 12:42:35',
				'serie_code': '5',
				'serie_num': '',
				'status': 'Регистрация ставок',
				'status_code': 'DRST_BET',
				'version': '5',
				'win_bdate': '2029-05-25 12:42:38',
				'win_edate': '2039-04-21 12:42:35',
				'jackpot': '123456789'
			}

		}, {
			'draw': {
				'bet_sum': '5.00',
				'code': '1525',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'

				},
				'dr_bdate': '2029-05-25 12:42:36',
				'dr_edate': '2029-05-25 12:42:37',
				'num': '604',
				'sale_bdate': '2029-04-11 12:42:35',
				'sale_edate': '2029-05-25 12:42:35',
				'serie_code': '5',
				'serie_num': '',
				'status': 'Регистрация ставок',
				'status_code': 'DRST_BET',
				'version': '5',
				'win_bdate': '2029-05-25 12:42:38',
				'win_edate': '2039-04-21 12:42:35',
				'jackpot': '123456789'
			}

		}, {
			'draw': {
				'bet_sum': '8.00',
				'code': '1526',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'

				},
				'dr_bdate': '2029-06-01 12:51:49',
				'dr_edate': '2029-06-01 12:51:50',
				'num': '605',
				'sale_bdate': '2029-04-11 12:51:48',
				'sale_edate': '2029-06-01 12:51:48',
				'serie_code': '5',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '5',
				'win_bdate': '2029-06-01 12:51:51',
				'win_edate': '2039-04-11 12:51:48'

			}

		}, {
			'draw': {
				'bet_sum': '5.00',
				'code': '1527',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'

				},
				'dr_bdate': '2029-06-09 13:00:47',
				'dr_edate': '2029-06-09 13:00:48',
				'num': '606',
				'sale_bdate': '2029-04-11 13:00:46',
				'sale_edate': '2029-06-09 13:00:46',
				'serie_code': '5',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '5',
				'win_bdate': '2029-06-09 13:00:49',
				'win_edate': '2039-04-11 13:00:46'

			}

		}],
		'lott_code': '2',
		'lott_name': 'Спортпрогноз'
	}
};
