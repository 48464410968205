import {Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Profile, ProfileKey} from '@app/util/profile';
import {PARAM_ACTION_BET, PARAM_DEMO_BET, URL_ACTIONS, URL_DEMO, URL_LOTTERIES} from '@app/util/route-utils';
import {DurationMinute, loadImage, numberFromStringCurrencyFormat, parseUrl, twoCol, ukrDate} from '@app/util/utils';
import {BarcodeReaderService} from '@app/core/barcode/barcode-reader.service';
import {EsapActions} from '@app/core/configuration/esap';
import {LotteryGameCode} from '@app/core/configuration/lotteries';
import {TransactionDialogComponent} from '@app/core/dialog/components/transaction-dialog/transaction-dialog.component';
import {DialogContainerService} from '@app/core/dialog/services/dialog-container.service';
import {DialogError, DialogInfo, ErrorCode} from '@app/core/error/dialog';
import {ApiError, AppError, CancelError, IError, NetError} from '@app/core/error/types';
import {ICancelableRequest, IResponse} from '@app/core/net/http/api/types';
import {HttpService} from '@app/core/net/http/services/http.service';
import {Logger} from '@app/core/net/ws/services/log/logger';
import {PrintService} from '@app/core/net/ws/services/print/print.service';
import {Format, PrintData, PrinterInfo, PrinterState} from '@app/core/net/ws/api/models/print/print-models';
import {ResponseCacheService} from '@app/core/services/response-cache.service';
import {PrintTicketInfo} from '@app/tickets-print/interfaces/print-ticket-info';
import {AppStoreService} from '@app/core/services/store/app-store.service';
import {PeripheralService} from '@app/core/services/peripheral.service';
import {
	CANCEL_TIMEOUT,
	INACTIVITY_DIALOG_TIMEOUT,
	StorageTicket,
	Transaction,
	TransactionState
} from '@app/core/services/transaction/transaction';
import {CancelFlag, ITransactionResponse, ITransactionTicket} from '@app/core/services/transaction/transaction-types';
import {HamburgerMenuService} from '@app/hamburger/services/hamburger-menu.service';
import {Template} from '@app/tickets-print/parser/template';
import {TotalCheckListState, TotalCheckStorageService} from '@app/total-check/services/total-check-storage.service';
import {ITransactionTicketTemplate} from '@app/tickets-print/interfaces/itransaction-ticket-template';
import {LogOutService} from '@app/logout/services/log-out.service';
import {AppType} from '@app/core/services/store/settings';
import {BehaviorSubject, forkJoin, from, Observable, of, timer} from 'rxjs';
import {ApiRequest, IPrinterInfoResponse} from '@app/core/net/ws/api/types';
import {catchError, concatMap, delay, filter, map, retry, tap, timeout} from 'rxjs/operators';
import { Text } from '@app/core/net/ws/api/models/print/print-models';
import {environment} from "@app/env/environment";

/**
 * Сервис предоставляющий функционал по управлению финансовой транзакцией.
 */
@Injectable({ providedIn: 'root' })
export class TransactionService {
	// -----------------------------
	//  Private properties
	// -----------------------------

	/**
	 * Модель финансовой транзакции хранящейся в памяти терминала.
	 */
	private transaction: Transaction;

	/**
	 * Признак того, что билеты были отменены.
	 * @private
	 */
	private ticketsWereCancelled = false;

	/**
	 * Была ли автоотмена?
	 * @private
	 */
	private wasAutoCancelled = false;

	/**
	 * Инфа о принтере
	 */
	printerInfo: PrinterInfo | null = null;

	/**
	 * Режим продажи
	 * 0 - обычный, 1 - через бланки
	 */
	saleMode = 0;

	/**
	 * Режим печати
	 * 0 - через XML-шаблон
	 * 1 - через картинку построчно
	 * 2 - картинка целиком
	 * 3 - через браузер (картинка целиком на веб-страницу)
	 */
	trsMode = 0;

	/**
	 * Количество загруженных изображений при регистрации лотереи
	 */
	loadedImagesCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	/**
	 * Последний ответ с билетами
	 * @private
	 */
	lastResponse$: BehaviorSubject<IResponse | null> = new BehaviorSubject<ITransactionResponse | null>(null);

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {HttpService} httpService Сервис для работы с http запросами.
	 * @param {AppStoreService} appStoreService Сервис для работы с хранилищем данных приложения.
	 * @param {PrintService} printService Сервис для работы с печатью.
	 * @param {DialogContainerService} dialogInfoService Сервис для работы с диалоговыми окнами.
	 * @param {TranslateService} translate Сервис для работы с переводами.
	 * @param {Router} router Сервис для работы с маршрутизацией.
	 * @param {BarcodeReaderService} barcodeReaderService Сервис для работы со сканером штрих-кодов.
	 * @param {TotalCheckStorageService} totalCheckService Сервис для работы с хранилищем данных чека.
	 * @param {HamburgerMenuService} hamburgerMenuService Сервис для работы с меню.
	 * @param {Injector} injector Инжектор зависимостей.
	 * @param {ResponseCacheService} responseCacheService Сервис для работы с кэшем ответов.
	 * @param {PeripheralService} peripheralService Сервис для работы с периферийными устройствами.
	 * @param {LogOutService} logOutService Сервис для работы с выходом из системы.
	 */
	constructor(
		private readonly httpService: HttpService,
		private readonly appStoreService: AppStoreService,
		private readonly printService: PrintService,
		private readonly dialogInfoService: DialogContainerService,
		private readonly translate: TranslateService,
		private readonly router: Router,
		private readonly barcodeReaderService: BarcodeReaderService,
		private readonly totalCheckService: TotalCheckStorageService,
		private readonly hamburgerMenuService: HamburgerMenuService,
		private readonly injector: Injector,
		private readonly responseCacheService: ResponseCacheService,
		private readonly peripheralService: PeripheralService,
		private readonly logOutService: LogOutService
	) {}

	/**
	 * Проверяет, на этапе инициализации терминала (перед окном авторизации) наличие незавершенной транзакции
	 * и при наличии такой вызывает процедуру отмены последней транзакции.
	 * Состояние текущей транзакции берется из Storage-сервиса.
	 *
	 * @returns {Promise<void>}
	 */
	checkLastTransactionState(): Observable<void> {
		Logger.Log.i('TransactionService', `checkLastTransactionState -> ${this.transactionState}`)
			.console();

		return from(this.check())
			.pipe(
				catchError(() => {
					const ticket = this.getCanceledTicketsString();
					Logger.Log.i('TransactionService', `checkLastTransactionState -> cancel with tickets: ${ticket}`)
						.console();

					if (!!ticket) {
						const str = 'dialog.terminal_off_error';
						// отрез бумаги на случай, если билет может быть недопечатан
						if (this.printService.isReady()) {
							this.printService.printDocument([], 5000)
								.then()
								.catch();
						}

						return from(this.cancelPrintError(new ApiError(str, null, ErrorCode.TicketPrint)));
					}

					return this.showOneButtonInfo('dialog.info_title', 'dialog.ticket_cancel_last_info')
						.pipe(
							concatMap(() => {
								return new Observable<void>(observer => {
									this.repeatCancel(() => {
										observer.next();
										observer.complete();
									});
								});
							})
						);
				})
			);
	}

	/**
	 * Запускает процедуру отмены последней транзакции с указанным флагом отмены.
	 *
	 * @param {CancelFlag} flag Флаг отмены.
	 * @returns {Promise<void>}
	 */
	cancelLast(flag: CancelFlag): Promise<void> {
		Logger.Log.i('TransactionService', `cancelLast -> ${this.transactionState}`)
			.console();

		return Promise.resolve()
			.then(() => {
				if (!this.transaction.hasSufficientParams()) {
					throw new AppError('dialog.last_transaction_does_not_exist', ErrorCode.LastTransactionExist);
				}

				this.transaction.cancelFlag = flag;
				this.transaction.tickets = undefined;

				return this.transaction.cancelState()
					.then(() => {
						this.dialogInfoService.showNoneButtonsInfo('dialog.in_progress', 'dialog.ticket_cancel_wait_info');

						return this.regularCancel(false);
					});
			});
	}

	/**
	 * Устанавливает последнюю транзакцию как неотменяемую вручную.
	 */
	setLastUnCanceled(): void {
		if (this.transaction) {
			this.transaction.canNotBeCancel = true;
		}

		this.hamburgerMenuService.deactivateCancelButton();
	}

	/**
	 * Функция покупки лотереи.
	 * @param T Тип ответа
	 * @param code Код лотереи
	 * @param request Запрос на покупку
	 */
	buyLottery<T extends ITransactionResponse>(T, code: LotteryGameCode, request: ICancelableRequest): void {
		const sellMethod = this.appStoreService.Settings.sellMethods.get(code);
		this.trsMode = +localStorage.getItem('TRS_MODE') || 0;
		const needsPrinting = sellMethod && sellMethod.printer;
		if (!needsPrinting || (needsPrinting && this.printService.isReady()) || (this.trsMode === 3)) {
			Profile.start();
			this.executeRequest(T, code, request)
				.then(() => {
					Logger.Log.i(T, 'buyLottery -> transaction executed successful')
						.console();

					Profile.end();
					Profile.getResult();
				})
				.catch((error: IError) => {
					Logger.Log.e('TransactionService',
						`buyLottery ->  transaction execute ERROR code: ${error.code}, message: ${error.message}`)
						.console();
					this.errorHandler(error);
				})
				.finally(() => {
					this.saleMode = 0;
				});
			} else {
				Logger.Log.i('TransactionService', 'buyLottery -> printer not ready')
					.console();
				this.saleMode = 0;
				this.dialogInfoService.showOneButtonInfo('dialog.attention', 'dialog.printer_not_ready_info', {
					text: 'dialog.dialog_button_continue'
				});
			}
	}

	/**
	 * Запускает процедуру продажи лотереи: регистрации ставки в ЦС, подготовки билетов к печати, печати билетов.
	 *
	 * @param T Тип ответа регистрируемой лотереи(нужен для валидации ответа).
	 * @param {LotteryGameCode} code Код лотереи.
	 * @param {ICancelableRequest} request Тело запроса.
	 * @returns {Promise<void>}
	 */
	executeRequest<T extends ITransactionResponse>(T, code: LotteryGameCode, request: ICancelableRequest): Promise<void> {
		Logger.Log.i('TransactionService', `executeRequest -> ${this.transactionState}`)
			.console();

		this.dialogInfoService.showTransactionDialog('dialog.in_progress', 'dialog.ticket_register_wait_info');
		this.dialogInfoService.updateTransactionDialog({extra: 'dialog.much_time_to_wait'});
		this.ticketsWereCancelled = false;
		this.wasAutoCancelled = false;
		this.loadedImagesCount$.next(0);

		let allTickets = [];

		return this.checkLastState()
			.then(() => {
				// выполнить запрос в ЦС
				return this.request(request, code)
					// обработать ответ
					.then((response: ITransactionResponse) => {
						allTickets = ('length' in response.ticket) ? response.ticket : [response.ticket];
						return this.response(T, code, response);
					})
					.then(response => {
						if ((this.trsMode === 3) && !!response.ticket[0].url) {
							const obs$ = allTickets
								.map((v) => {
									return loadImage(this.appStoreService.Settings.trsBaseURL + v.url, environment.production);
								})
							return forkJoin(obs$)
								.pipe(
									tap((imagesData: Array<HTMLImageElement>) => {
										const newResponse = {...response};
										newResponse.ticket = allTickets.map((v, i) => {
											return {
												...v,
												imageData: imagesData[i].src
											};
										});
										this.lastResponse$.next({...newResponse});
									}),
									concatMap(() => {
										return from(new Promise((resolve) => {
											this.loadedImagesCount$.subscribe((imgNum) => {
												console.log('imgNum =', imgNum);
												if (imgNum === allTickets.length) {
													resolve(imgNum);
												}
											})
										}));
									})
								).toPromise();
						}
						this.lastResponse$.next({...response});

						if ((code === 100) && (!this.appStoreService.cardNum)) {
							return Promise.resolve();
						}

						return this.printing(code, response).toPromise();
					})
					// success
					.then(() => {
						this.totalCheckService.register(request, this.lastResponse$.value as ITransactionResponse);

						return this.success();
					})
					// check for cancel last
					.then(() => {
						return this.canCancelLast()
							.then(canCancel => {
								canCancel
									? this.hamburgerMenuService.activateCancelButton()
									: this.hamburgerMenuService.deactivateCancelButton();
							});
					})
					// error, try to cancel
					.catch((error: IError) => {
						if (this.lastResponse$.value) {
							this.totalCheckService.register(request, this.lastResponse$.value as ITransactionResponse, this.transaction);
						}
						this.ticketsWereCancelled = true;

						return this.error(error);
					})
					// return to lotteries
					.then(() => {
						if ((this.trsMode === 3) && (!this.ticketsWereCancelled)) {
							window.print();
 						} else {
							this.routeLotteries();
							if (!this.ticketsWereCancelled) {
								this.dialogInfoService.showOneButtonInfo('dialog.info_title', 'dialog.op_successful', {
									text: 'dialog.dialog_button_continue',
									click: () => {}
								});
							}
						}
					});
			})
			.catch(this.onCatchError.bind(this));
	}

	/**
	 * Установить транзакцию в состояние невозможно к дальнейшей отмене (например при ошибке отмены).
	 */
	setCannotBeUndoneState(): Promise<void> {
		return this.transaction.setCannotBeUndoneState();
	}

	// -----------------------------
	//  Private functions
	// -----------------------------
	/**
	 * Обработчик ошибок.
	 * @param error Ошибка.
	 * @private
	 */
	private errorHandler(error: IError): void {
		this.dialogInfoService.showOneButtonError(new DialogError(error.code, error.message, error.messageDetails), {
			click: () => {
				if ((error.code === 4313) || (error.code === 4318)) {
					this.logOutService.logoutOperator();
				}
			},
			text: 'dialog.dialog_button_continue'
		});
	}

	/**
	 * Проверка текущего состояния финансовой транзакции.
	 *
	 * @returns {Promise<void>}
	 */
	private check(): Promise<void> {
		Logger.Log.i('TransactionService', `check -> ${this.transactionState}`)
			.console();

		if (this.transaction) {
			return this.transaction.isClean
				? Promise.resolve()
				: Promise.reject(new Error(`check -> transaction unclean, ${this.transactionState}`));
		}

		// модель транзакции не создана - создаем и загружаем в нее данные из хранилища
		this.transaction = new Transaction(this.injector);

		return this.transaction.load()
			.then(() => {
				if (!this.transaction.isClean) {
					return Promise.reject(new Error(`check -> transaction unclean, ${this.transactionState}`));
				}
			});
	}

	/**
	 * Проверка текущего состояния транзакции в начале каждой новой финансовой транзакции.
	 *
	 * @returns {Promise<void>}
	 */
	private checkLastState(): Promise<void> {
		Logger.Log.i('TransactionService', `checkLastState -> ${this.transactionState}`)
			.console();

		return this.check()
			.catch(() => {
				Logger.Log.i('TransactionService', `checkLastState -> cancel with tickets: ${this.getCanceledTicketsString()}`)
					.console();

				return new Promise(resolve => {
					this.dialogInfoService.showOneButtonInfo('dialog.info_title', 'dialog.ticket_cancel_last_info', {
						text: 'dialog.dialog_button_continue',
						click: resolve
					});
				})
					.then(() => {
						this.dialogInfoService.showNoneButtonsInfo('dialog.in_progress', 'dialog.ticket_cancel_wait_info');

						return this.regularCancel(false)
							.catch((error: IError) => {
								Logger.Log.e('TransactionService', `checkLastState -> ${this.transactionState}, message: ${error.message}`)
									.console();
								throw new CancelError(error);
							});
					});
			});
	}

	/**
	 * Выполнение запроса на регистрацию ставки в ЦС.
	 *
	 * @param {ICancelableRequest} request Тело запроса регистрации ставки.
	 * @param {LotteryGameCode} code Код лотереи.
	 * @returns {Promise<IResponse>}
	 */
	private request(request: ICancelableRequest, code: LotteryGameCode): Promise<IResponse> {
		Logger.Log.i('TransactionService', `request -> ${this.transactionState}`)
			.console();

		return this.transaction.requestState(request, code)
			.catch((error: IError) => {
				this.transaction.cleanState();
				throw error;
			})
			.then(() => {
				Profile.startCheckPoint(ProfileKey.BuyRequest);

				return this.httpService.sendApi(request)
					.catch((error: IError) => {
						Profile.stopCheckPoint(ProfileKey.BuyRequest);

						this.transaction.cancelFlag = CancelFlag.Auto_NoCSAnswer;
						if (error instanceof ApiError) {
							if (error.code) {
								return this.transaction.errorState()
									.then(() => {
										throw error;
									})
									.catch(() => {
										throw error;
									});
							}
						}
						throw error;
					});
			});
	}

	/**
	 * Обработка положительного ответа от ЦС.
	 *
	 * @param T Тип ответа регистрируемой лотереи.
	 * @param {LotteryGameCode} code Код лотереи.
	 * @param {IResponse} response Ответ принятый от ЦС.
	 * @returns {Promise<ITransactionResponse>}
	 */
	private response(T, code: LotteryGameCode, response: IResponse): Promise<ITransactionResponse> {
		Logger.Log.i('TransactionService', `response [%s] -> ${this.transactionState}}`, T.name)
			.console();

		this.transaction.cancelFlag = CancelFlag.Auto_PrintError;

		let amount = 0;
		const tickets: Array<StorageTicket> = [];
		const regBetResponse = response as ITransactionResponse;

		try {
			Logger.Log.i('TransactionService', `creating tickets array from response and calculate amount`)
				.console();

			const allTickets = ('length' in regBetResponse.ticket) ? regBetResponse.ticket : [regBetResponse.ticket]

			for (const ticket of allTickets) {
				const betSum = typeof ticket.bet_sum === 'string'
					? numberFromStringCurrencyFormat(ticket.bet_sum)
					: ticket.bet_sum * 100;

				amount += betSum;

				tickets.push({ id: ticket.id, description: ticket.description, price: betSum, printed: false});
			}
			Logger.Log.i('TransactionService', `created tickets array: %s`, tickets)
				.console();
		} catch (error) {
			Logger.Log.e('TransactionService', `can't create tickets array: %s`, error)
				.console();

			throw new AppError(error.message, error.code ? error.code : ErrorCode.TicketPrint);
		}

		return this.transaction.responseState(tickets)
			.then(() => {
				Logger.Log.i('TransactionService', `amount = %s`, amount)
					.console();

				return regBetResponse;
			})
			.catch((error: IError) => {
				Logger.Log.e('TransactionService', `can't register response: %s`, error)
					.console();
				throw new AppError(error.message, error.code ? error.code : ErrorCode.TicketPrint);
			});
	}

	/**
	 * Функция формирование билетов для печати при выборе графического режима.
	 * @param ticket Объект с информацией о билете.
	 * @param trsMode Режим печати (построчно или целиком)
	 * @param pixelsPerLine Количество пикселей на линию
	 * @private
	 */
	private graphicalMode(ticket: ITransactionTicket, trsMode: number, pixelsPerLine: number): Observable<PrintTicketInfo> {
		const fullImageURL = environment.mockData ? '/assets/img/test-ticket.png' : `${this.appStoreService.Settings.trsBaseURL}${ticket.url}`;

		return loadImage(fullImageURL, environment.production)
			.pipe(
				map(image => {
					const printData = [];

					if (trsMode === 1) {
						const imagePieces = [];
						const numRowsToCut = (image.height % 24) === 0 ? image.height / 24 : Math.floor(image.height / 24) + 1;


						for (let y = 0; y < numRowsToCut; y++) {
							const canvas = document.createElement('canvas');
							canvas.width = pixelsPerLine;
							canvas.height = 24;
							const context = canvas.getContext('2d');
							context.drawImage(image, 0, y * 24, pixelsPerLine, 24, 0, 0, canvas.width, canvas.height);
							imagePieces.push(canvas.toDataURL());
						}

						imagePieces.forEach((imageP, ind) => {
							printData.push({control: 'image', data: imageP.substr(22), key: `${fullImageURL}-${ind}.png`});
							printData.push({control: 'text', data: '\n'});
						});
					} else {
						printData.push({control: 'image', data: image.src.substr(22), key: `${fullImageURL}`});
					}

					return {
						ticket,
						result: printData
					};
				})
			);
	}

	/**
	 * Послать запрос статуса принтера и получить ответ
	 * @private
	 */
	private sendPrinterStatus(): Observable<PrinterState> {
		this.printService.sendApiRequest(new ApiRequest('ua.msl.alt.service.printer', 'status'));

		return new Observable<PrinterState>(observer => {
			this.printService.wsObserver$$
				.pipe(
					filter(v => !!v?.data),
					map(v => JSON.parse(v.data)),
					filter(v => !!v?.notification),
					map(v => v.notification?.state || null)
				)
				.subscribe(state => {
					if (state === PrinterState.OnLine) {
						observer.next(PrinterState.OnLine);
					} else {
						observer.error(state);
					}
					observer.complete();
				});
		});
	}

	/**
	 * Проверка статуса принтера
	 * @private
	 */
	private checkPrintStatus(): Observable<PrinterState> {
		return this.sendPrinterStatus()
			.pipe(
				timeout(3000),
				retry(4)
			);
	}

	/**
	 * Процедура подготовки к печати и дальнейшей печати списка билетов.
	 * Каждый билет предварительно форматируется, на основании шаблона полученного из ЦС и данных ответа ЦС при регистрации ставки.
	 * Затем форматированные данные отправляются на печать.
	 *
	 * @param {LotteryGameCode} code Код лотереи.
	 * @param {ITransactionResponse} response Объект с ответом ЦС на регистрацию ставки.
	 * @returns {Promise<void>}
	 */
	private printing(code: LotteryGameCode, response: ITransactionResponse): Observable<{}> {
		Logger.Log.i('TransactionService',
			`printing -> ${this.transactionState}, game code: (${code}), ticket is Array: (${Array.isArray(response.ticket)})`)
			.console();
		this.dialogInfoService.updateTransactionDialog({extra: '', message: 'dialog.ticket_printing_wait_info'});
		let idx = 0;

		this.printService.printerIsBusy = true;
		let tNum = 0;
		let pixelsPerLine = 384;
		this.trsMode = +localStorage.getItem('TRS_MODE') || 0;

		const allTickets = ('length' in response.ticket) ? response.ticket : [response.ticket]

		return this.printService.getPrinterInfo()
			.pipe(
				timeout(1000),
				catchError(() => {
					console.log('Информация про принтер не получена в течении таймаута!');
					const sPrinterInfo = localStorage.getItem('PRINTER_INFO');
					this.printerInfo = sPrinterInfo ? JSON.parse(sPrinterInfo) : null;

					return of({ printerInfo: this.printerInfo, requestId: '0', errorCode: 0, errorDesc: '' });
				}),
				concatMap((printerInfoResponse: IPrinterInfoResponse) => {
					this.printerInfo = printerInfoResponse.printerInfo;
					pixelsPerLine = this.printerInfo?.pixelsPerLine || 384;

					return from(allTickets);
				}),
				filter((ticket: ITransactionTicket) => (this.trsMode && !!ticket.url)
					|| !!response.ticket_templ_url
					|| !!ticket.ticket_templ_url
					|| (this.saleMode === 1)
				),
				concatMap((ticket: ITransactionTicket) => of(ticket).pipe(
					concatMap((ticket: ITransactionTicket) => {
						tNum++;
						Logger.Log.i('TransactionService', `printing -> ticket (${ticket.id}) will formatted and printed`)
							.console();

						console.log(`Билет${tNum} = `, ticket);

						// задать прогресс в диалоге транзакции
						const progress = (idx++ + 1) * 100 / allTickets.length;
						this.dialogInfoService.updateTransactionDialog({progress});

						this.transaction.canNotBeCancel = this.saleMode === 1;

						if (code === 100) {
							return this.prepareTMLReceipt({ticket});
						}

						return (this.saleMode === 1) || (this.trsMode === 0) ?
							from(this.format(code, response, {ticket})) :
							this.graphicalMode(ticket, this.trsMode, pixelsPerLine);
					}),
					concatMap(formattedTicketInfo => {
						this.printService.lastDocID = '';

						return from(this.print(formattedTicketInfo));
					}),
					delay(5000),
					concatMap(this.checkPrintStatus.bind(this)),
					catchError(() => this.printingFail(response, tNum)),
					tap(v => {
						if (v !== PrinterState.OnLine) {
							this.printingFail(response, tNum);
						}
						this.printService.printerIsBusy = false;
					})
				))
			);
	}

	/**
	 * Процедура неудачного завершения печати.
	 * @param response Ответ ЦС
	 * @param tNum Количество билетов
	 * @private
	 */
	private printingFail(response: ITransactionResponse, tNum: number): Observable<void> {
		const allTickets = ('length' in response.ticket) ? response.ticket : [response.ticket]

		if (this.transaction.tickets.length === 0) {
			let k = 0;
			for (const ticket2 of allTickets) {
				k++;
				this.transaction.tickets.push({
					id: ticket2.id,
					description: ticket2.description,
					price: +ticket2.bet_sum,
					printed: k < tNum
				});
			}
		}
		const str = this.translate.instant('dialog.printer_time_out');
		this.wasAutoCancelled = true;

		return from(this.cancelPrintError(new ApiError(str, null, ErrorCode.TicketPrint)));
	}

	/**
	 * Процедура завершения успешной продажи лотереи.
	 *
	 * @returns {Promise<void>}
	 */
	private success(): Promise<void> {
		Logger.Log.i('TransactionService', `success -> ${this.transactionState}`)
			.console();

		return this.transaction.successState()
			.then(() => {
				return new Promise<void>(resolve => {
					this.dialogInfoService.hideAll();
					resolve();
				});
			});
	}

	/**
	 * Идентифицирует, можно ли вручную отменять последнюю транзакцию.
	 *
	 * @returns {Promise<boolean>}
	 */
	private canCancelLast(): Promise<boolean> {
		Logger.Log.i('TransactionService', `canCancelLast()`)
			.console();

		return new Promise<boolean>(resolve => {
			this.check()
				.then(() => {
					if (this.transaction.state === TransactionState.Canceled || this.transaction.state === TransactionState.CannotBeUndone
						|| Date.now() - this.transaction.store.regdate >= CANCEL_TIMEOUT || this.transaction.canNotBeCancel
						|| this.cantCancelLastTransactionByLottery || this.wasAutoCancelled
					) {
						resolve(false);
					}

					resolve(true);
				})
				.catch(() => {
					if (Date.now() - this.transaction.store.regdate >= CANCEL_TIMEOUT
						|| this.transaction.canNotBeCancel
						|| this.cantCancelLastTransactionByLottery
					) {
						resolve(false);
					}

					resolve(true);
				});
		});
	}

	/**
	 * Проверить можно ли отменять последнюю транзакцию.
	 * Проверка будет выполнена на основе списка ключей {@link EsapActions.restrictedToCancelActions}.
	 *
	 * @returns {boolean} Возвращает <code>true</code>, если завершение транзакции невозможно.
	 */
	private get cantCancelLastTransactionByLottery(): boolean {
		Logger.Log.i('TransactionService', `cantCancelLastTransactionByLottery -> try to check last transaction data for canceling...`)
			.console();

		if (!this.transaction.store.params) {
			Logger.Log.i(
				'TransactionService',
				`cantCancelLastTransactionByLottery -> can't cancel, no transaction data: %s`, this.transaction.store)
				.console();

			return true;
		}

		// по имени запрещенных экшенов получить массивы кодов лотерей для этих экшенов
		const code = this.transaction.store.params.game_code;
		const restrictedCodesArr = EsapActions.restrictedToCancelActions
			.map(v => this.appStoreService.Settings.getLotteryCodesByEsapActionName(v));

		// проверить наличие кода лотереи из транзакции в массивах запрещенных лотерей
		const result = restrictedCodesArr.reduce((p, c) => p || (c.indexOf(code) !== -1), false);
		Logger.Log.i('TransactionService', `cantCancelLastTransactionByLottery -> can cancel last transaction? -> ${!result}`)
			.console();

		return result;
	}

	/**
	 * Процедура поведения транзакции при регистрации любой ошибки.
	 *
	 * @param {IError} error Ошибка возникающая в ходе выполнения транзакции.
	 * @returns {Promise<void>}
	 */
	private error(error: IError): Promise<void> {
		Logger.Log.e('TransactionService', `error -> ${this.transactionState}, message: ${error.message}`)
			.console();

		switch (this.transaction.state) {
			case TransactionState.Request:
				return this.cancelRequestError(error);

			case TransactionState.Response:
			case TransactionState.Print:
			case TransactionState.Printed:
				return this.cancelPrintError(error);

			default:
				throw error;
		}
	}

	/**
	 * Основная процедура отмены транзакции.
	 * В зависимости от наличия напечатанных билетов вызывается либо отмена всей транзакции либо отмена только нераспечатанных билетов.
	 *
	 * @param {number} retryCount
	 * @returns {Promise<void>}
	 */
	private cancel(retryCount: number): Promise<void> {
		// есть ли проблема с объектом транзакции (1122)?
		if (!this.transaction) {
			return Promise.reject(new ApiError(`dialog.transaction_model_error`, undefined, ErrorCode.TransactionModelError));
		}

		Logger.Log.i('TransactionService', `cancel -> flag: ${this.transaction.cancelFlag}, tickets: ${this.getCanceledTicketsString()}`)
			.console();

		// выполнить запрос в ЦС на отмену
		return this.httpService.sendApi(this.transaction.makeCancelRequest())
			.then(() => {
				// в общий чек заносим успешную отмену (неуспешная отмене не заносится)
				this.totalCheckService.cancel(this.transaction);
				this.totalCheckService.operationListState$$.next(TotalCheckListState.Expanded);
				this.hamburgerMenuService.deactivateCancelButton();

				const isLogged = this.appStoreService.hasOperator() && this.appStoreService.operator.value.isValid();
				if (this.transaction.hasPrinted) {
					if (isLogged) {
						let amount = 0;
						this.transaction.getPrintedTickets()
							.forEach(ticket => amount += ticket.price);
					}

					return this.transaction.partialSuccessState();
				}

				return this.transaction.canceledState();
			})
			.catch((error: IError) => {
				if (error instanceof ApiError) {
					if (error.code) {
						if (this.transaction.isCancelError(error.code)) {
							Logger.Log.e('TransactionService', `cancel error, code: ${error.code}`)
								.console();

							return this.transaction.errorState()
								.then(() => {
									throw error;
								});
						}

						throw error;
					}
				}

				// выдать юзеру сообщение и подождать 5 минут до повтора (или по клику юзера повторить)
				return new Promise<void>(resolve => {
					this.showTicketCancelErrorDialog(resolve);
					this.logOutService.enableShutdown();
					this.logOutService.deadLockAction = () => {
						this.showTicketCancelErrorDialog(resolve);
					};
				})
					.then(() => {
						this.dialogInfoService.showNoneButtonsInfo('dialog.in_progress', 'dialog.ticket_cancel_wait_info');
						this.logOutService.disableShutdown();
						this.logOutService.deadLockAction = null;

						return this.cancel(2);
					});
			});
	}

	/**
	 * Показывает диалоговое окно с сообщением об ошибке отмены транзакции
	 * @param click - обработчик клика
	 */
	private showTicketCancelErrorDialog(click: () => void): void {
		this.dialogInfoService.showOneButtonError(new ApiError(undefined, 'dialog.ticket_cancel_last_error_repeat'), {
			click,
			text: 'dialog.dialog_button_continue'
		}, {
			firstButtonAutoClickTimeOut: DurationMinute * 5,
			hideOnClick: true
		});
	}

	/**
	 * Показывает диалоговое окно с сообщением и одной кнопкой
	 * @param title Заголовок
	 * @param message Сообщение
	 * @private
	 */
	private showOneButtonInfo(title: string, message: string | DialogInfo): Observable<void> {
		return new Observable<void>(observer => {
			this.dialogInfoService.showOneButtonInfo(title, message, {
				click: () => {
					observer.next();
					observer.complete();
				},
				text: 'dialog.dialog_button_continue'
			});
		});
	}

	/**
	 * Блок для вызова повторов отмены транзакции.
	 *
	 * @param {(value?: any) => void} resolve Обработчик клика кнопки "Продолжить" (успешной отмены)
	 */
	private repeatCancel(resolve: (value?: any) => void): void {
		Logger.Log.i('TransactionService', `repeatCancel -> ${this.transactionState}`)
			.console();

		this.dialogInfoService.showNoneButtonsInfo('dialog.in_progress', 'dialog.ticket_cancel_wait_info');
		this.cancel(2)
			.then(() => {
				this.dialogInfoService.showOneButtonInfo('dialog.info_title', 'dialog.ticket_register_cancel_ok_info', {
					click: resolve,
					text: 'dialog.dialog_button_continue'
				});
			})
			.catch((error: IError) => {
				const v1 = (error instanceof NetError || error instanceof AppError) ? this.translate.instant(error.message) : '';
				const errorText = this.translate.instant('dialog.ticket_cancel_last_error', {v1});
				this.dialogInfoService.showOneButtonError(new DialogError(error.code, errorText), {
					click: () => {
						if (error instanceof ApiError) {
							// проверить на 1122
							if (error.code && error.code === ErrorCode.TransactionModelError) {
								this.transaction = new Transaction(this.injector);

								return;
							}

							if (error.code && this.transaction && this.transaction.isCancelError(error.code)) {
								return resolve();
							}
						}

						this.repeatCancel(resolve);
					},
					text: 'dialog.dialog_button_continue'
				});
			});
	}

	/**
	 * Блок для вызова обычной (регулярной) отмены транзакции.
	 *
	 * @param {boolean} isPrinting Идентифицирует отмену при начале процедуры печати билетов.
	 * @returns {Promise<void>}
	 */
	private regularCancel(isPrinting: boolean): Promise<void> {
		Logger.Log.i('TransactionService', `regularCancel -> ${this.transactionState}`)
			.console();

		return this.cancel(2)
			.then(() => {
				let message: string | DialogInfo;
				if (isPrinting) {
					message = this.transaction.hasPrinted
						? new DialogInfo('dialog.ticket_print_cancel_ok_info', this.transaction.getLastPrintedDesc())
						: 'dialog.ticket_register_cancel_ok_info';
				} else {
					message = 'dialog.ticket_register_cancel_ok_info';
				}

				return new Promise<void>(resolve => {
					this.dialogInfoService.hideAll();
					this.dialogInfoService.showNoneButtonsInfo('dialog.complete', message, {smooth: true});
					const tmr = timer(2000)
						.subscribe(() => {
							this.dialogInfoService.hideAll();
							tmr.unsubscribe();
						});
					resolve();
					// this.dialogInfoService.showOneButtonInfo('dialog.complete', message, {
					// 	text: 'dialog.dialog_button_continue',
					// 	click: resolve
					// });
				});
			})
			.catch((error: IError) => {
				return new Promise<void>((resolve, reject) => {
					const v1 = (error instanceof NetError || error instanceof AppError) ? error.message : '';
					let v2;
					let message;

					if (isPrinting) {
						if (this.transaction.hasPrinted) {
							message = 'dialog.ticket_print_cancel_error';
							v2 = this.transaction.getLastPrintedDesc();
						} else {
							message = 'dialog.ticket_cancel_last_error';
						}
					} else {
						message = 'dialog.ticket_cancel_last_error';
					}

					const errorText = this.translate.instant(message, {v1, v2});
					this.dialogInfoService.showOneButtonError(new DialogError(error.code, errorText), {
						click: () => {
							if (error instanceof ApiError) {
								if (error.code && this.transaction.isCancelError(error.code)) {
									resolve();
								}
							}
							reject(error);
						},
						text: 'dialog.dialog_button_continue'
					});
				});
			});
	}

	/**
	 * Блок отмены вызываемый, при наличии ошибки, на этапе до начала процедуры печати.
	 *
	 * @param {IError} error Ошибка
	 * @returns {Promise<void>}
	 */
	private cancelRequestError(error: IError): Promise<void> {
		Logger.Log.i('TransactionService', `cancelRequestError -> ${this.transactionState}`)
			.console();

		let str1: string;
		let str2: string;
		let code: number;
		if (error instanceof ApiError) {
			code = error.code;
			str1 = 'dialog.ticket_register_error';
			str2 = error.message;
		} else {
			code = error.code ? error.code : ErrorCode.TicketRegister;
			str1 = 'dialog.ticket_register_error_cancel';
			str2 = error instanceof NetError || error instanceof AppError || 'message' in error
				? error.message
				: 'dialog.error_title';
		}

		return new Promise<void>(resolve => {
			const v1 = this.translate.instant(str2);
			const value = this.translate.instant(str1, {v1});
			const dialog = new DialogError(code, value);
			this.dialogInfoService.showOneButtonError(dialog, {
				click: resolve,
				text: 'dialog.dialog_button_continue'
			}, {
				firstButtonAutoClickTimeOut: INACTIVITY_DIALOG_TIMEOUT
			});
		})
			.then(() => {
				if (!(error instanceof ApiError)) {
					this.dialogInfoService.showNoneButtonsInfo('dialog.in_progress', 'dialog.ticket_cancel_wait_info');

					return this.regularCancel(false)
						.catch(() => {
							Logger.Log.e('TransactionService',
								`cancelRequestError -> state: ${this.transaction.state}, message: ${error.message}`)
								.console();
							this.routeLotteries();
						});
				}
			});
	}

	/**
	 * Блок отмены, вызываемый при наличии ошибки на этапе процедуры печати билетов.
	 *
	 * @param {IError} error Ошибка.
	 * @returns {Promise<void>}
	 */
	private cancelPrintError(error: IError): Promise<void> {
		Logger.Log.i('TransactionService', `cancelPrintError -> ${this.transactionState}`)
			.console();

		return new Promise(resolve => {
			const code = error.code ? error.code : ErrorCode.TicketPrint;
			const str = this.transaction.hasPrinted ? 'dialog.ticket_print_error_cancel_print' : 'dialog.ticket_print_error_cancel';
			const value = this.translate.instant(str, {
				v1: this.translate.instant(error.message),
				v2: this.transaction.getNumberOfPrinted(),
				v3: this.transaction.getNumberOfTickets(),
				v4: this.transaction.getLastPrintedDesc()
			});

			Logger.Log.i('TransactionService', `cancelPrintError -> code: ${code}, text: ${value}`)
				.console();
			this.dialogInfoService.showOneButtonError(new DialogError(code, value), {
				click: resolve,
				text: 'dialog.dialog_button_continue'
			}, {
				firstButtonAutoClickTimeOut: INACTIVITY_DIALOG_TIMEOUT
			});
		})
			.then(() => {
				this.dialogInfoService.showNoneButtonsInfo('dialog.in_progress', 'dialog.ticket_cancel_wait_info');

				this.clearBuffer();

				return this.regularCancel(true)
					.catch(() => {
						Logger.Log.e('TransactionService', `cancelPrintError, state: ${this.transaction.state}, message: ${error.message}`)
							.console();
						this.routeLotteries();
					});
			});
	}

	/**
	 * Формирование данных для получения шаблона для печати билета.
	 *
	 * @param {ITransactionResponse} response Ответ ЦС в котором происходит поиск данных для получения шаблона.
	 * @param {ITransactionTicket} ticket Номер билета в массиве.
	 * @returns {ITransactionTicketTemplate}
	 */
	private getTicketTemplate(response: ITransactionResponse, ticket: ITransactionTicket): ITransactionTicketTemplate {
		let path: string;
		if (ticket.ticket_templ_url) {
			path = ticket.ticket_templ_url;
		} else if (response.ticket_templ_url) {
			path = response.ticket_templ_url;
		} else {
			throw new Error(`expected property "ticket_templ_url" not found in response during print operation`);
		}

		// подготовить URL шаблона для печати чека
		if (path.indexOf('/') === 0) {
			path = path.substring(1);
		}
		const requestUrl = parseUrl(this.transaction.store.params.url);
		const port = requestUrl.port ? `:${requestUrl.port}` : '';
		const url = `${requestUrl.origin}${port}/${path}`;

		return {path, url};
	}

	prepareTMLReceipt(printTicketInfo: PrintTicketInfo): Observable<PrintTicketInfo> {
		let receipt: Array<PrintData> = [
			new Format('center'),
			new Text('КВИТАНЦІЯ\n'),
			new Format('left'),
			new Text('про реєстрацію продажу ТМЛ\n')
		];

		let totalSum = 0;

		for (const serie of printTicketInfo.ticket.serie) {
			receipt = [
				...receipt,
				new Text(serie.name + '\n'),
				new Text(twoCol('Білети', serie.range[0].numbers, this.printerInfo.charsPerLine) + '\n'),
				new Format('right')
			];

			let tCount = +serie.range[0].count;
			let tSum = parseFloat(serie.range[0].sum.toString());

			for (let i = 1; i < serie.range.length; i++) {
				tCount += +serie.range[i].count;
				tSum += parseFloat(serie.range[i].sum.toString());
				receipt.push(new Text(serie.range[i].numbers + '\n'));
			}

			receipt = [
				...receipt,
				new Format('left'),
				new Text(twoCol('Кількість білетів', tCount.toString(), this.printerInfo.charsPerLine) + '\n'),
				new Text(twoCol('Вартість білетів', `${tSum.toFixed(2)} грн`, this.printerInfo.charsPerLine) + '\n'),
				new Text('-'.repeat(this.printerInfo.charsPerLine) + '\n')
			];
			totalSum += tSum;
		}


		const bonusUAH = this.appStoreService.bonusPaySum / 100;
		const bonusGift = bonusUAH.toFixed(2);
		const paySum = totalSum - bonusUAH;

		receipt = [
			...receipt,
			new Text(twoCol('Вартість білетів', `${totalSum.toFixed(2)} грн`, this.printerInfo.charsPerLine) + '\n'),
			new Text(twoCol('Подарунок бонусами', `${bonusGift} грн`, this.printerInfo.charsPerLine) + '\n'),
			new Text(twoCol('Сума до сплати', `${paySum.toFixed(2)} грн`, this.printerInfo.charsPerLine) + '\n'),
			new Text(twoCol(ukrDate(new Date()), `Термінал ${this.appStoreService.Settings.termCode}`, this.printerInfo.charsPerLine) + '\n')
		];

		return of({
			ticket: printTicketInfo.ticket,
			result: receipt
		});
	}

	/**
	 * Форматирование билета для печати, на основании шаблона полученного из ЦС и данных ответа ЦС при регистрации ставки.
	 *
	 * @param {LotteryGameCode} code Код лотереи.
	 * @param {ITransactionResponse} response Ответ ЦС на регистрацию.
	 * @param {PrintTicketInfo} printTicketInfo Модель для хранения данных о процессе печати билета.
	 * @returns {Promise<Array<PrintData>>}
	 */
	private format(code: LotteryGameCode, response: ITransactionResponse, printTicketInfo: PrintTicketInfo): Promise<PrintTicketInfo> {
		Logger.Log.i('TransactionService',
			`format -> ${this.transactionState}, game code: (${code}), ticket ID: (${printTicketInfo.ticket.id})`)
			.console();
		Profile.startCheckPoint(ProfileKey.PrepareTicketForPrinting);
		const template = new Template(this.appStoreService, code, this.responseCacheService, this.httpService);
		const ticketTemplate = this.getTicketTemplate(response, printTicketInfo.ticket);

		const isBlank = this.saleMode === 1;

		return template.formatTicket(response, printTicketInfo.ticket, ticketTemplate, isBlank)
			.toPromise()
			.then(printContent => {

				// Говно-костыль для печати квитанции бланков на узких принтерах
				const pixelsPerLine = this.printerInfo?.pixelsPerLine || 384;
				console.log(pixelsPerLine);
				let newPrintContent = printContent;
				if ((this.saleMode === 1) && (pixelsPerLine === 384)) {
					// Говно-костыль для печати на узких принтерах
					// https://msl.atlassian.net/browse/CS-995
					const stKey = 'Дата та час реєстрації';
					const sRegTimeIndex = newPrintContent.findIndex(v => (v as any).data?.startsWith(stKey));
					if (sRegTimeIndex > -1) {
						const stDate = (newPrintContent[sRegTimeIndex + 1] as Text).data.trim();
						newPrintContent.splice(sRegTimeIndex, 1, new Text(stKey.padEnd(31) + "\n"));
						newPrintContent.splice(sRegTimeIndex + 1, 1, new Text(stDate.padStart(31) + "\n"));
					}
				}
				Profile.stopCheckPoint(ProfileKey.PrepareTicketForPrinting);
				printTicketInfo.result = newPrintContent;

				return printTicketInfo;
			})
			.catch(error => {
				printTicketInfo.error = error;

				return printTicketInfo;
			});
	}

	/**
	 * Процедура печати, одного, билета на основе подготовленных (форматированных) данных.
	 *
	 * @param {PrintTicketInfo} printTicketInfo Модель билета для печати.
	 * @returns {Promise<void>}
	 */
	private print(printTicketInfo: PrintTicketInfo): Promise<void> {
		Logger.Log.i('TransactionService', `print -> ${this.transactionState}, ticket ID: (${printTicketInfo.ticket.id})`)
			.console();
		if (printTicketInfo.result) {
			Profile.startCheckPoint(ProfileKey.PrintTicket);

			return this.transaction.printState()
				.then(() => {
					return this.printService.printDocument(printTicketInfo.result, 30000)
						.then(docId => {
							(this.dialogInfoService.transactionDialog.dialogComponent as TransactionDialogComponent).hideExtraMessage();
							Profile.stopCheckPoint(ProfileKey.PrintTicket);
							Logger.Log.i('TransactionService', `print -> document PRINTED, docId: ${docId}`)
								.console();

							return this.transaction.printedState(printTicketInfo.ticket.id);
						})
						.catch((error: IError) => {
							Profile.stopCheckPoint(ProfileKey.PrintTicket);
							Logger.Log.e('TransactionService', `print -> printer ERROR, code:${error.code}, message: ${error.message}`)
								.console();

							this.clearBuffer();

							throw error;
						});
				});
		}

		return Promise.resolve(null);
	}

	/**
	 * Отправка запроса на очистку буфера печати.
	 * @private
	 */
	private clearBuffer(): void {
		if (this.printService.lastDocID) {
			this.printService.sendApiRequest(new ApiRequest('ua.msl.alt.service.printer', 'cancelPrint', this.printService.lastDocID));
			this.printService.lastDocID = '';
		}
	}

	/**
	 * Получить строку с ID отмененных билетов.
	 * @private
	 */
	private getCanceledTicketsString(): string {
		if (this.transaction && this.transaction.hasUnPrinted(this.transaction.tickets)) {
			return this.transaction.getCanceledTickets()
				.join(',');
		}
	}

	/**
	 * Перейти на страницу лотерей.
	 * @private
	 */
	private routeLotteries(): void {
		this.peripheralService.startExternalDevices();

		// определить страницу возврата и перейти к ней
		let returnUrl = URL_LOTTERIES;
		const urlTree = this.router.parseUrl(this.router.url);
		if (urlTree.queryParamMap.has(PARAM_ACTION_BET)) {
			returnUrl = URL_ACTIONS;
		}
		if (urlTree.queryParamMap.has(PARAM_DEMO_BET)) {
			returnUrl = URL_DEMO;
		}
		this.router.navigate([returnUrl])
			.catch(err => Logger.Log.e('TransactionService', `routeLotteries -> can't navigate to return page: ${err}`)
				.console());
	}

	/**
	 * Обработка ошибок.
	 * @param error Ошибка.
	 * @private
	 */
	private onCatchError(error: IError): void {
		if (error instanceof CancelError) {
			return this.routeLotteries();
		}

		let err: NetError | DialogError;
		err = (error instanceof NetError) ? error : new DialogError(ErrorCode.Transaction, 'dialog.transaction_error');
		if ((this.appStoreService.Settings.appType !== AppType.ALTTerminal) && (err.code === ErrorCode.IncorrectSMS)) {
			this.appStoreService.smsCodeError = true;
			this.dialogInfoService.hideAll();
		} else {
			this.dialogInfoService.showOneButtonError(err, {
				click: () => {
					if ((error.code === 4313) || (error.code === 4318)) {
						this.logOutService.logoutOperator();
					} else {
						this.routeLotteries();
					}
				},
				text: 'dialog.dialog_button_continue'
			});
		}
	}

	/**
	 * Выводит текстовое представление текущего статуса транзакции.
	 */
	private get transactionState(): string {
		if (this.transaction) {
			return `current transaction state: ${TransactionState[this.transaction.state]} (${this.transaction.state})`;
		}

		return `unknown transaction state`;
	}
}
