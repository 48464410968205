import { HttpHeaders } from '@angular/common/http';
import { getEsapRequestId, TicketFormat } from '@app/util/utils';
import { EsapActions, EsapParams } from '@app/core/configuration/esap';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { LotteryGameCode } from '@app/core/configuration/lotteries';
import { IActionUrl, ICancelableRequest, IRequest } from '@app/core/net/http/api/types';
import { IBetDataItem } from '@app/core/game/base-game.service';
import { PrinterInfo } from '@app/core/net/ws/api/models/print/print-models';

/**
 * Лотереи с шаблонами билетов.
 */
const TEMPLATE_LOTTERIES = new Map<string, boolean>([
	[EsapActions.ZabavaRegBet, true],
	[EsapActions.MegalotRegBet, true],
	[EsapActions.EInstantRegBet, true],
	[EsapActions.GonkaRegBet, true],
	[EsapActions.TipTopRegBet, true],
	[EsapActions.KareRegBet, true],
	[EsapActions.SportPrognozRegBet, true]
]);

/**
 * Хранилище параметров запросов.
 */
export class URLSearchParams {

	// -----------------------------
	//  Private properties
	// -----------------------------

	/**
	 * Хеш-таблица параметров.
	 * @private
	 */
	private readonly map = new Map<string, string>();

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Добавить параметр.
	 *
	 * @param {string} key Ключ.
	 * @param {string} value Значение.
	 */
	append(key: string, value: string): void {
		this.map.set(key, value);
	}

	/**
	 * Получить параметр по ключу.
	 *
	 * @param {string} key Ключ.
	 */
	get(key: string): string {
		return this.map.get(key);
	}

	/**
	 * Установить значение для ключа.
	 *
	 * @param {string} key Ключ
	 * @param {string} value Значение
	 */
	set(key: string, value: string): void {
		this.map.set(key, value);
	}

	/**
	 * Удалить ключ со значением.
	 *
	 * @param {string} key Ключ
	 */
	remove(key: string): void {
		this.map.delete(key);
	}

	/**
	 * Преобразовать хешмап параметров в строку.
	 */
	toString(): string {
		const entries = this.map.entries();
		let result = '';
		let ir: IteratorResult<[string, string]>;
		let first = true;
		do {
			ir = entries.next();
			if (!ir.done) {
				result += `${first ? '' : '&'}${ir.value[0]}=${ir.value[1]}`;
				first = false;
			}
		} while (!ir.done);

		return result;
	}

}

/**
 * Базовый клас для построения тела запроса при взаимодействии с сервисами ЦС.
 */
export class ApiClient implements IRequest {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Контейнер с параметрами запроса.
	 */
	params: URLSearchParams;

	/**
	 * Заголовок запроса.
	 */
	headers: HttpHeaders;

	/**
	 * URL, по которому производится запрос.
	 */
	url: string;

	/**
	 * Идентификатор транзакции.
	 */
	trans_id: string;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор запроса к ЦС.
	 *
	 * @param {AppStoreService} appStoreService Ссылка на сервис-хранилище приложения.
	 * @param {IActionUrl} actionUrl Информация о соответствии URL запроса к сервису ЦС имени парметра ACTION.
	 */
	constructor(appStoreService: AppStoreService, actionUrl: IActionUrl) {
		this.headers = new HttpHeaders();
		this.headers.append('Content-Type', 'application/x-www-form-urlencoded');

		this.params = new URLSearchParams();
		this.trans_id = getEsapRequestId();

		if (actionUrl && actionUrl.url && actionUrl.action) {
			this.url = actionUrl.url;

			this.params.append(EsapParams.ACTION, actionUrl.action);
			this.params.append(EsapParams.PROTO_VER, '3');

			if (actionUrl.action === EsapActions.SportPrognozRegBet) {
				this.params.remove(EsapParams.PROTO_TYPE);
			} else {
				this.params.append(EsapParams.PROTO_TYPE, 'keyvalue-json');
			}


			this.params.append(EsapParams.CLIENT_TRANS_ID, this.trans_id);
			if (appStoreService.Settings.termCode) {
				this.params.append(EsapParams.TERM_CODE, appStoreService.Settings.termCode);
			}
			if (appStoreService.Settings.clientID) {
				this.params.append(EsapParams.CLIENT_ID, appStoreService.Settings.clientID);
			}
			this.params.append(EsapParams.CHANNEL_TYPE, 'web_alt');
			this.params.append(EsapParams.LANG, appStoreService.Settings.lang);
		}

		// ////////////
		if (appStoreService.hasOperator()) {
			this.params.append(EsapParams.USER_ID, appStoreService.operator.value.userId);
			this.params.append(EsapParams.SID, appStoreService.operator.value.sessionId);
		}
		// ///////////////////////
	}
}

/**
 * Модель для построения тела запроса при взаимодействии с сервисами ЦС (для транзакционных запросов).
 */
export class CancelableApiClient extends ApiClient implements ICancelableRequest {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Идентификатор пользователя.
	 */
	user_id: string;

	/**
	 * Идентификатор сессии пользователя.
	 */
	sess_id: string;

	/**
	 * Код лотереи.
	 */
	gameCode: LotteryGameCode;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор запроса к ЦС.
	 *
	 * @param {AppStoreService} appStoreService Ссылка на сервис-хранилище приложения.
	 * @param {IActionUrl} actionUrl Информация о соответствии URL запроса к сервису ЦС имени параметра ACTION.
	 * @param {IBetDataItem} betData Параметры ставки.
	 */
	constructor(
		appStoreService: AppStoreService,
		actionUrl: IActionUrl,
		betData: IBetDataItem
	) {
		super(appStoreService, actionUrl);

		if (appStoreService.hasOperator()) {
			this.user_id = appStoreService.operator.value.userId;
			this.sess_id = appStoreService.operator.value.sessionId;

			// this.params.append(EsapParams.USER_ID, this.user_id);
			// this.params.append(EsapParams.SID, this.sess_id);
			// this.params.remove(EsapParams.PROTO_TYPE);

			// если в параметрах запроса задан параметр saleMode
			if (!!betData && betData.saleMode === 1) {
				this.params.append(EsapParams.SALE_MODE, '1');
			}

			if (!!appStoreService.playerInfo) {
				this.params.append(EsapParams.PLAYER_PHONE, appStoreService.playerInfo.playerPhone);
				this.params.append(EsapParams.PLAYER_AUTH_CODE, appStoreService.playerInfo.playerAuthCode);
			}

			if (actionUrl.action.endsWith('RegBet')) {
				if (appStoreService.cardNum) {
					this.params.append(EsapParams.CARD_NUM, appStoreService.cardNum);
				}

				if (appStoreService.bonusPaySum) {
					this.params.append(EsapParams.BONUS_PAY_SUM, appStoreService.bonusPaySum.toString());
				}
			}

			// Для веб-альта добавить параметр TICKET_FORMAT="PNG", если у лотереи есть шаблон билета
			const trsMode = +localStorage.getItem('TRS_MODE') || 0;
			if ((trsMode !== 0) && TEMPLATE_LOTTERIES.get(actionUrl.action)) {
				const sPrinterInfo = localStorage.getItem('PRINTER_INFO');
				const printerInfo: PrinterInfo = sPrinterInfo ? JSON.parse(sPrinterInfo) : null;
				const ticketFormat = actionUrl.action === EsapActions.SportPrognozRegBet
					? TicketFormat.PNG_384
					: printerInfo?.pixelsPerLine === 504
						? TicketFormat.PNG_504 : TicketFormat.PNG_384;
				this.params.append(EsapParams.TICKET_FORMAT, ticketFormat);
			}
		}
	}
}
