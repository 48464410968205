import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError, TimeoutError, timer } from 'rxjs';
import { delayWhen, finalize, mergeMap, retryWhen, tap, timeout } from 'rxjs/operators';
import { ErrorCode } from '../../error/dialog';
import { ApiError, NetError } from '../../error/types';
import { ProtocolsType, Settings } from '../../services/store/settings';
import {IClientResponseObserver, IMessage, IResponse} from './api/types';
import { Logger } from '@app/core/net/ws/services/log/logger';

/**
 * Таймаут по умолчанию для запросов (в миллисекундах).
 */
const DEFAULT_TIMEOUT = 30 * 1000;

/**
 * Количество повторов запроса по умолчанию.
 */
const DEFAULT_RETRY_COUNT = 3;

/**
 * Задержка между повторами запроса по умолчанию (в миллисекундах).
 */
const DEFAULT_RETRY_DELAY = 20 * 1000;

/**
 * Реализуемые типы запросов (посредством http).
 * - {@link Esap} - запрос по протоколу ESAP
 * - {@link Get} - обычный запрос типа GET
 * - {@link Post} - обычный запрос типа POST
 */
export enum HttpRequestType {
	Esap = 'esap',
	Get = 'get',
	Post = 'post'
}

/**
 * Модель базового сетевого транспорта посредством протокола HTTP
 * (механизма для взаимодействия приложения и внешних сервисов)
 */
export class Transport {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Ссылка на настройки приложения.
	 */
	settings: Settings;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор.
	 *
	 * @param {HttpClient} httpClient
	 */
	constructor(
		protected httpClient: HttpClient
	) {}

	/**
	 * Отправка HTTP запроса заданного типа и конфигурации.
	 *
	 * @param {HttpRequestType} type Тип запроса {@link HttpRequestType}.
	 * @param {Observable<HttpResponse<string>>} observable Объект, инкапсулирующий запрос (выполняющий работу по обмену данными).
	 * @param {IClientResponseObserver} observer Объект, наблюдающий за ходом выполнения запроса.
	 * @param {number} requestTimeout Таймаут на выполнения запроса в ms (необязательный параметр).
	 * @param {number} requestRetryCount Кол-во повторов запроса до момента генерации ошибки (необязательный параметр).
	 */
	protected sendRequest(
		type: HttpRequestType,
		observable: Observable<HttpResponse<string>>,
		observer: IClientResponseObserver,
		requestTimeout?: number,
		requestRetryCount?: number
	): void {
		// настроить дефолтные значения таймаутов и повторов
		let actualTimeout = DEFAULT_TIMEOUT;
		let actualRetryDelay = DEFAULT_RETRY_DELAY;
		let actualRetryCount = DEFAULT_RETRY_COUNT;

		// если в конфигурации присутствует соответствующий параметр, то принять его значение
		const protocol = this.settings.getProtocolByType(ProtocolsType.ESAP);
		if (this.settings && !!protocol && !!protocol.params) {
			if (Number.isInteger(protocol.params.responseTimeout)) {
				actualTimeout = protocol.params.responseTimeout * 1000;
			}

			if (Number.isInteger(protocol.params.retryDelay)) {
				actualRetryDelay = protocol.params.retryDelay * 1000; // TODO исправить после добавления
			}

			if (Number.isInteger(protocol.params.maxAttempt)) {
				actualRetryCount = protocol.params.maxAttempt;
			}
		}

		// если в запросе указаны свои параметры, то использовать их
		if (Number.isInteger(requestTimeout)) {
			actualTimeout = requestTimeout;
		}
		if (Number.isInteger(requestRetryCount)) {
			actualRetryCount = requestRetryCount;
		}

		Logger.Log.i('Transport', `sendRequest -> timeout: ${actualTimeout}, delay: ${actualRetryDelay}, retry count: ${actualRetryCount}`)
			.console();

		let startAttempt = Date.now();
		let calculatedDelay: number;
		observable
			.pipe(
				timeout(actualTimeout),
				retryWhen(errors => errors.pipe(
					mergeMap((error, i) => {
						// оборвать повтор запроса при достижении максимального значения
						const attempt = i + 1;
						if (attempt > actualRetryCount) {
							return throwError(error);
						}

						// расчитать задержку до повтора
						const lastAttemptTime = Date.now() - startAttempt;
						calculatedDelay = actualRetryDelay - lastAttemptTime;
						calculatedDelay = calculatedDelay < 0 ? 0 : calculatedDelay;

						// залогировать соответствующее сообщение об ошибке
						if (error instanceof HttpErrorResponse) {
							Logger.Log.e('Transport', `request ERROR - http error response: %s:`, error)
								.console();
						} else if (error instanceof TimeoutError) {
							Logger.Log.e('Transport', 'request ERROR - timeout error %s:', error)
								.console();
						} else {
							Logger.Log.e('Transport', `request ERROR - undefined error: %s` , error)
								.console();
						}

						Logger.Log.i('Transport',
							`ATTEMPT #${attempt} will be started during ${calculatedDelay}(ms), last attempt time: ${lastAttemptTime}(ms)`)
							.console();

						return of(error);
					}),
					delayWhen(() => timer(calculatedDelay)),
					tap(() => {
						Logger.Log.i('Transport', `the timer has STARTED for a new ATTEMPT.`)
							.console();
						startAttempt = Date.now();
					}),
					finalize(() => {
						Logger.Log.i('Transport', `branch with errors was FINISHED successfully`)
							.console();
					})
				))
			)
			.subscribe(resp => {
				Logger.Log.i('Transport', `got http response: ${resp.body}`)
					.console();

				// запросы ESAP и обычные GET/POST обрабатываются отдельно
				if (type === HttpRequestType.Esap) {
					Logger.Log.i('Transport', `identified as ESAP response.`)
						.console();

					// попробовать распарсить JSON
					let message: IMessage;
					let netError: NetError;
					try {
						message = JSON.parse(resp.body) as IMessage;
					} catch (e) {
						try {
							const respond = this.parseXML(resp.body, ['ticket']) as IMessage;
							message = { respond } as IMessage;
						} catch (e2) {
							// попытаться определить 502/504 ошибку по контенту
							const err502 = '502 Bad Gateway';
							const err504 = '504 Gateway Time-out';
							// eslint-disable-next-line
							if (resp && `${resp.body}`.includes(err502)) {
								netError = new NetError('dialog.network_error', err502, 502);
							} else if (resp && `${resp.body}`.includes(err504)) {
								netError = new NetError('dialog.network_error', err504, 504);
							} else {
								netError = new NetError((e as Error).message);
							}
						}
					}

					if (netError) {
						return observer.onError(netError);
					}

					// JSON обработан, проверить ответ на ошибки API (err_code)
					const messageRespond = message.respond || (message as IResponse);
					if (messageRespond) {
						if (+messageRespond.err_code === 0) {
							observer.onResult(messageRespond);
						} else {
							Logger.Log.e('Transport', `ESAP error %s:`, message)
								.console();

							const err = new ApiError(messageRespond.err_descr, undefined, messageRespond.err_code);
							observer.onError(err);
						}
					} else {
						const err = new NetError(`Can't find "message.respond" parameter in the ESAP answer`);
						Logger.Log.e('Transport', `${err.message}`)
							.console();
						observer.onError(err);
					}
				} else {
					Logger.Log.i('Transport', `identified as GET or POST response.`)
						.console();
					observer.onResult(resp.body);
				}
			}, (error: HttpErrorResponse | NetError | TimeoutError) => {
				if (error instanceof HttpErrorResponse) {
					Logger.Log.e('Transport', `http error response: %s`, error)
						.console();

					const errorCode = error.status ? error.status : ErrorCode.HttpRequest;
					const errorMessage = new NetError('dialog.network_error', error.statusText, errorCode);
					observer.onError(errorMessage);
				} else if (error instanceof NetError) {
					Logger.Log.e('Transport', `network error: %s`, error)
						.console();

					observer.onError(new NetError('dialog.network_error', error.message, ErrorCode.Network));
				} else if (error instanceof TimeoutError) {
					Logger.Log.e('Transport', `timeout error: %s`, error)
						.console();

					observer.onError(new NetError('dialog.network_error', 'Timeout', ErrorCode.Network));
				} else {
					Logger.Log.e('Transport', `undefined error: %s`, error)
						.console();

					observer.onError(new NetError('Undefined Error', undefined, undefined));
				}
			}, () => {
				Logger.Log.i('Transport', `subscription to %s request complete`, type.toUpperCase())
					.console();
			}
		);
	}

	private parseXML(xml: string, arrayElements: string[] = []): any {
		const parser = new DOMParser();
		const xmlDoc = parser.parseFromString(xml, 'application/xml');
		return this.xmlToJson(xmlDoc.documentElement, arrayElements);
	}

	private xmlToJson(node: any, arrayElements: string[]): any {
		// Create the return object
		let obj: any = {};

		// If node has attributes, add them
		if (node.nodeType === 1 && node.attributes.length > 0) {
			obj["@attributes"] = {};
			for (let i = 0; i < node.attributes.length; i++) {
				const attribute = node.attributes.item(i);
				if (attribute) {
					obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
				}
			}
		}

		// If node has child nodes, process them
		if (node.hasChildNodes()) {
			for (let i = 0; i < node.childNodes.length; i++) {
				const childNode = node.childNodes.item(i);
				const nodeName = childNode.nodeName;

				if (childNode.nodeType === 3) { // Text node
					const textContent = childNode.textContent?.trim();
					if (textContent) {
						return textContent; // Return the text content if it's a simple text node
					}
				} else {
					if (!(nodeName in obj)) {
						obj[nodeName] = this.xmlToJson(childNode, arrayElements);
					} else {
						if (!Array.isArray(obj[nodeName])) {
							obj[nodeName] = [obj[nodeName]];
						}
						obj[nodeName].push(this.xmlToJson(childNode, arrayElements));
					}
				}
			}
		}

		// Convert to array if the element is specified in arrayElements
		for (const key in obj) {
			if (arrayElements.includes(key) && !Array.isArray(obj[key])) {
				obj[key] = [obj[key]];
			}
		}

		return obj;
	}

}
